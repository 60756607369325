import React, { useEffect, useState } from 'react';
import { TextField, Checkbox, Button, FormControlLabel, FormGroup, Box, Typography, Autocomplete, Tooltip } from '@mui/material';
import { useMsal, useAccount } from "@azure/msal-react"
import axios from "axios"
import { useRegistration } from '../context';
import CircularProgress from '@mui/material/CircularProgress';
import RegistrationSucess from "./RegistrationSuccess"
import RegistrationFailure from './RegistrationFailure';
import { useDialog } from '../components/DialogProvider';
// import adList from "../staticDAL/adlist.json"
import { useCallback } from 'react';
import { useADGroups } from '../hooks/useADGroups';

function RegistrationForm() {
    const { formData, updateApiResponse, updateFromData, resetForm } = useRegistration()
    const [isLoading, setLoading] = useState(false)
    const [adOptions] = useADGroups();
    const { instance, accounts } = useMsal()
    const [isAdvanced, setAdvanced] = useState(false)
    const account = useAccount(accounts[0]);
    const handleChange = (event) => {

        const { name, value, checked } = event.target;
        const payload = {}
        if (name === 'sendInvitationMessage')
            payload[name] = checked
        else if (name === 'ccRecipients')
            payload[name] = [value]
        else
            payload[name] = value
        updateFromData(payload);
    };

    const { updateDialog } = useDialog();



    const printToken = useCallback(async () => {

        const tokens = await instance.acquireTokenSilent({
            scopes: [process.env.REACT_APP_API_SCOPE], //Modify scope only when necessary
            account: account
        })
        console.debug("access token: ", tokens.accessToken)
        console.debug("id token: ", tokens.idToken)
        console.debug("token claims: ", tokens.idTokenClaims)
    }, [account, instance])


    useEffect(() => {


        printToken();

    }, [printToken])

    const filter = (event, value, reason) => {



        if (reason === "clear") {

            updateFromData({
                inviteUserByADGroupName: { label: "Select", value: "select" },
            });
        }



    }


    const isUrlValid = (string) => {
        try {
            new URL(string);
            return true;
        } catch (err) {
            return false;
        }
    }
    const isValidLanguageCode = (input) => {
        const languageRegex = /^[a-z]{2}(?:-[A-Z]{2})?$/
        return languageRegex.test(input)
    }

    // function isUUID(uuid) {
    //     let s = "" + uuid;

    //     s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    //     if (s === null) {
    //         return false;
    //     }
    //     return true;
    // }

    const analyzeErrorFromResponse = (response) => {

        let message = null;
        let { detailMessage } = response

        if (detailMessage?.includes("Error")) {
            if (detailMessage.includes("object references already exist")) {

                message = "User already exists, Please try with a different user."
            } else {
                message = response?.detailMessage
            }


        }
        return message
    }
    const handleSubmit = async (event) => {
        setLoading(true)
        event.preventDefault();

        var canSubmitForm = true;


        if (canSubmitForm) {
            try {



                let apiRequestBody = { ...formData }
                apiRequestBody.inviteUserByADGroupName = formData.inviteUserByADGroupName.value;
                if (!isAdvanced) {
                    const { ccRecipients,
                        invitedUserDisplayName,
                        messageLanguage,
                        employeeNumber, ...rest } = apiRequestBody
                    apiRequestBody = rest
                }
                const tokens = await instance.acquireTokenSilent({
                    scopes: [process.env.REACT_APP_API_SCOPE], //Modify scope only when necessary
                    account: account
                })

                let data = JSON.stringify(apiRequestBody);

                // APi call to create invitation service

                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${process.env.REACT_APP_API_URL}/external/es/azure/invitation/create-invitation`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + tokens.accessToken
                    },
                    data: data
                };

                axios.request(config)
                    .then((response) => {
                        console.debug(JSON.stringify(response.data));

                        let error = analyzeErrorFromResponse(response.data)
                        if (error) {
                            updateApiResponse({ status: "error", response: error })
                            updateDialog({ open: true, title: <div><Typography variant='h6' style={{ color: "red" }}>Invitation Failed</Typography></div>, content: <RegistrationFailure /> })
                        } else {
                            updateApiResponse({ status: "success", response: JSON.stringify(response.data) })
                            updateDialog({ open: true, title: <div><Typography variant='h6' style={{ color: "green" }}>Invitation Success</Typography></div>, content: <RegistrationSucess /> })
                        }

                        // navigate("/success")

                        setLoading(false)
                        resetForm()
                        setAdvanced(false)
                    })
                    .catch((error) => {
                        console.debug(error);
                        updateApiResponse({ status: "error", response: error.toString() })
                        // navigate("/failed")
                        updateDialog({ open: true, title: <div><Typography variant='h6' style={{ color: "red" }}>Invitation Failed</Typography></div>, content: <RegistrationFailure /> })
                        setLoading(false)
                        resetForm()
                        setAdvanced(false)
                    });


            } catch (err) {
                console.error("Token Error: ", err.toString())
                setLoading(false)
                resetForm()
                setAdvanced(false)
            }


        }



    };

    return (

        <Box style={{ width: "60%", margin: "auto" }}>



            <form onSubmit={handleSubmit}>
                <Tooltip placement='top-start' title='As per Microsoft, service can only accept individual email address, will NOT accept Group/Shared email address.'>
                    <TextField
                        label="Email"
                        variant="outlined"
                        name="invitedUserEmailAddress"
                        value={formData.invitedUserEmailAddress}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                        type='email'
                        helperText='Note: As per Microsoft, service can only accept individual email address, will NOT accept Group/Shared email address.'
                        onBlur={(e) => e.target.reportValidity()}
                        inputProps={{ "data-testid": "email-input" }}
                    />
                </Tooltip>

                <Tooltip title='To be defined'>
                    <TextField
                        label="Application URL"
                        variant="outlined"
                        name="inviteRedirectUrl"
                        value={formData.inviteRedirectUrl}
                        onChange={handleChange}
                        inputProps={{ "data-testid": "redirect-uri-input" }}
                        fullWidth
                        margin="normal"
                        required
                        onBlur={(e) => {
                            isUrlValid(e.target.value) ? e.target.setCustomValidity("") : e.target.setCustomValidity("Please enter a valid url")
                            e.target.reportValidity();
                        }
                        }
                    />
                </Tooltip>

                <TextField
                    label="User Type"
                    variant="outlined"
                    name="invitedUserType"
                    value={formData.invitedUserType}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    disabled
                    onBlur={(e) => e.target.reportValidity()}
                />
                <TextField
                    label="Invitation Message"
                    variant="outlined"
                    name="customizedMessageBody"
                    value={formData.customizedMessageBody}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    onBlur={(e) => e.target.reportValidity()}
                    inputProps={{ "data-testid": "custom-message" }}
                />
                <Autocomplete
                    sx={{ marginTop: "15px" }}
                    data-testid="ad-group"
                    disablePortal
                    id="inviteUserByADGroupName"
                    options={adOptions}
                    value={formData.inviteUserByADGroupName}
                    onInputChange={filter}
                    isOptionEqualToValue={(option, value) =>
                        value === undefined ||
                        option.value === value.value
                    }
                    onChange={(e, option) => {
                        option && updateFromData({
                            inviteUserByADGroupName: option,
                        });
                    }}

                    renderInput={(params) => <TextField required {...params} label="User AD Group" />}
                />
                <TextField
                    label="Employee Number"
                    variant="outlined"
                    name="employeeNumber"
                    type='number'
                    value={formData.employeeNumber}
                    onChange={handleChange}
                    fullWidth
                    sx={{ display: isAdvanced ? 'block' : 'none' }}
                    margin="normal"
                    onBlur={(e) => e.target.reportValidity()}
                    inputProps={{ "data-testid": "employee-number" }}
                />
                <TextField
                    label="Email Language"
                    variant="outlined"
                    name="messageLanguage"
                    value={formData.messageLanguage}
                    onChange={handleChange}
                    fullWidth
                    sx={{ display: isAdvanced ? 'block' : 'none' }}
                    margin="normal"
                    onBlur={(e) => {
                        isValidLanguageCode(e.target.value) ? e.target.setCustomValidity("") : e.target.setCustomValidity("Please enter a valid Language code")
                        e.target.reportValidity();
                    }
                    }
                    inputProps={{ "data-testid": "message-language" }}
                />
                <TextField
                    label="Display Name"
                    variant="outlined"
                    name="invitedUserDisplayName"
                    value={formData.invitedUserDisplayName}
                    onChange={handleChange}
                    fullWidth
                    sx={{ display: isAdvanced ? 'block' : 'none' }}
                    margin="normal"
                    onBlur={(e) => e.target.reportValidity()}
                    inputProps={{ "data-testid": "display-name" }}
                />
                <TextField
                    label="CC Recipients"
                    variant="outlined"
                    name="ccRecipients"
                    value={formData.ccRecipients}
                    onChange={handleChange}
                    fullWidth
                    sx={{ display: isAdvanced ? 'block' : 'none' }}
                    margin="normal"
                    onBlur={(e) => e.target.reportValidity()}
                    inputProps={{ "data-testid": "cc-recipients" }}
                />

                <FormGroup>
                    <Box display={'flex'}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="sendInvitationMessage"
                                    checked={formData.sendInvitationMessage}
                                    onChange={handleChange}
                                />
                            }
                            label="Send Invitation Email"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="isAdvanced"
                                    checked={isAdvanced}
                                    onChange={(e) => { setAdvanced(e.target.checked) }}
                                />
                            }
                            label="Additional Parameters"
                        />
                    </Box>

                </FormGroup>
                <Box sx={{
                    display: "flex",
                    justifyContent: "flex-end"
                }}>
                    <Button onClick={() => resetForm()} style={{ marginRight: "20px" }} variant="outlined" color="primary">
                        Clear
                    </Button>
                    {isLoading ? <CircularProgress /> : <Button data-testid="submit-btn" type="submit" sx={{ width: "190px" }} variant="contained" color="primary">
                        Send Invitation
                    </Button>}



                </Box>

            </form >
        </Box>


    );
}

export default RegistrationForm;